<template>
  <el-dialog title="启用账号" :visible.sync="enableUserVisible" :close-on-click-modal="false" width="500px" append-to-body :before-close="handleClose">
    <div class="info">
      您确定要启用该账号吗？
    </div>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleClose">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "enableUserCount",
    props: {
      enableUserVisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:enableUserVisible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
</style>
